import React                from 'react';

import * as FixedRouteType from 'shared/types/FixedRoute';

import Alert                from 'utils/Alert';
import tectransit           from 'utils/TecTransit';
import MapOptions           from 'utils/MapOptions';
import getApiPromise        from 'utils/getApiPromise';
import arrayDedupe          from 'shared/utils/arrayDedupe';
import * as MenuItem        from 'components/MenuItem';

// This loosely follows https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu

interface Props {
    params? : Record<string,any>;
}

export class FixedRoutes extends React.Component {

    // @ts-expect-error
    public  props          : Props;

    private alert          : Alert;
    private googleMap?     : google.maps.Map;
    private shapePolyline? : google.maps.Polyline;
    private stopMarkers    : google.maps.Marker[] = [];
    private mapBoundaries? : google.maps.Polyline[];

    private static strokeColors = [
        "red",
        "green",
        "blue",
        "yellow",
        "pink",
        "black",
        "DarkCyan",
        "DarkGoldenRod",
        "DarkSalmon",
        "DarkViolet"
    ];

    public state : {
        dbTrips  : FixedRouteType.DBTrip[];
        frTrip?  : FixedRouteType.Trip;
    } = {
        dbTrips    : []
    };

    private setPolylinePath = ( frTrip:FixedRouteType.Trip ) => {
        if( !frTrip ) {
            this.alert.set(`Please choose a trip`);
            return;
        }
        const shapes = frTrip.shapes;
        if( !Array.isArray(shapes) || shapes.length<2 ) {
            this.alert.set(`Shapes is invalid`);
            return;
        }
        if( this.shapePolyline )
            this.shapePolyline.setMap(null);
        this.shapePolyline = new window.google.maps.Polyline({
            map           : this.googleMap,
            path          : shapes,
            editable      : true,
            strokeColor   : 'black', // (FixedRoutes.strokeColors.includes(frTrip.route_name.toLowerCase()) ? frTrip.route_name : 'black'),
            strokeOpacity : 1.0,
            strokeWeight  : 3
        });
        this.shapePolyline.addListener('click', (e:google.maps.PolyMouseEvent) => {
            if( window.confirm(`Delete vertex #${e.vertex}?`) ) {
                const path = this.shapePolyline?.getPath();
                if( path ) {
                    path.removeAt(e.vertex!);
                    this.shapePolyline?.setPath(path);
                }
            }
        });
    }
    private setStopMarkers = ( frTrip:FixedRouteType.Trip ) => {
        if( !frTrip ) {
            this.alert.set(`Please choose a trip`);
            return;
        }
        const stops = frTrip.stops;
        if( !Array.isArray(stops) || stops.length<2 ) {
            this.alert.set(`Stops is invalid`);
            return;
        }
        // Remove the old markers
        this.stopMarkers.forEach( m => {
            m.setMap(null);
        });
        // Create new stop markers
        this.stopMarkers = stops.map( (s,sndx) => {
            const stopMarker = new google.maps.Marker({
                map      : this.googleMap,
                title    : `Stop #${s._id} at ${s.name}\n`+
                    `Arrival at ${s.stop_time.arrival_time}\n`+
                    `Departure at ${s.stop_time.departure_time}\n`,
                label    : String(s._id),
                position : s,
                draggable: true,
                icon     : {
                    path          : google.maps.SymbolPath.CIRCLE,
                    strokeWeight  : 2,
                    strokeColor   : 'black', // (FixedRoutes.strokeColors.includes(frTrip!.route_name.toLowerCase()) ? frTrip!.route_name : 'black'),
                    scale         : 10
                }
            });
            stopMarker.addListener('click',() => {
                window.open(`https://www.google.com/maps/place/${s.lat},${s.lng}`,'googleMap');
            });
            stopMarker.addListener('dragend', (e:google.maps.MapMouseEvent) => {
                console.log(`Stop #${s._id} is dragged to ${e.latLng?.lat()},${e.latLng?.lng()}`)
            });
            return stopMarker;
        });
    }
    private onSelectTrip = ( tripId:number ) => {
        return getApiPromise<FixedRouteType.Trip>('/api/manager/fixedRoute/trip','GET',undefined,{trip_id:tripId})
            .then( frTrip => {
                if( !frTrip || frTrip.err )
                    throw Error(frTrip?.err||'frTrip is empty');
                this.setState({frTrip},() => {
                    this.setPolylinePath(frTrip);
                    this.setStopMarkers(frTrip);
                    this.alert.set(`Loaded ${frTrip.stops.length} stops and ${frTrip.shapes.length} points of shape #${arrayDedupe(frTrip.shapes.map(s=>s.shape_id))}`,3000);
                });
            })
            .catch( err => {
                this.alert.set(`Cannot find trip (${err.message})`);
            });
    }
    private downloadGtfs = () => {
        window.location.href = `/api/public/fixedRoute/gtfs`;
    }
    private resetShape = () => {
        if( !this.state.frTrip ) {
            this.alert.set(`Please select a trip`);
            return;
        }
        if( !window.confirm(`Are you sure to reset the trop to ${this.state.frTrip.stops.length} stop points?`) ) {
            //this.alert.set(`Resetting shape is cancelled`);
            return;
        }
        const frTrip = {
            ...this.state.frTrip,
            shapes : this.state.frTrip.stops.map( (stop,ndx) => {
                return {
                    lat     : stop.lat,
                    lng     : stop.lng,
                    shape_id: this.state.frTrip!.shape_id,
                    ndx     : ndx,
                    stop_id : stop._id
                };
            })
        };
        this.setState({frTrip},() => {
            this.setPolylinePath(frTrip);
        });
    }
    private saveTrip = ( frTrip:Partial<FixedRouteType.Trip> ) : Promise<FixedRouteType.Trip|undefined> => {
        try {
            if( !this.state.frTrip )
                throw Error(`Please select a trip`);
            if( JSON.stringify(this.state.frTrip.stops)===JSON.stringify(frTrip.stops) ) {
                console.log(`Stops did not change`);
                delete frTrip.stops;
            }
            // There is a problem with shapes comparison because shape._id does not survive the UI/UX
            if( JSON.stringify(this.state.frTrip.shapes)===JSON.stringify(frTrip.shapes) ) {
                console.log(`Shapes did not change`);
                delete frTrip.shapes;
            }
            FixedRouteType.validateTrip({...this.state.frTrip,...frTrip});
            frTrip._id = this.state.frTrip._id;
            return getApiPromise<FixedRouteType.Trip>('/api/manager/fixedRoute/trip','PUT',frTrip)
                .then( validFrTrip => {
                    if( !validFrTrip || validFrTrip.err )
                        throw Error(validFrTrip?.err||`newFrTrip is empty`);
                    this.setState({
                        frTrip : validFrTrip
                    },() => {
                        if( frTrip.shapes )
                            this.setPolylinePath(validFrTrip);
                        if( frTrip.stops )
                            this.setStopMarkers(validFrTrip);
                    });
                    return validFrTrip;
                })
                .catch( err => {
                    this.alert.set(`Cannot save route (${err.message})`);
                    return undefined;
                });
        }
        catch(err) {
            this.alert.set(`Cannot save route (${(err as Error).message})`);
            return Promise.resolve(undefined);
        }
    }
    constructor( props:Props ) {
        super(props);
        this.alert = new Alert();
        getApiPromise<FixedRouteType.DBTrip[]>('/api/manager/fixedRoute/trips')
            .then( dbTrips => {
                if( !dbTrips || dbTrips.err )
                    throw Error(dbTrips?.err||'trips are empty');
                dbTrips.sort((t1,t2)=>t1.name.localeCompare(t2.name));
                this.setState({dbTrips});
                return dbTrips;
            })
            .then( trips => {
                const tripId = parseInt(this.props.params?.tripId||trips[0]._id);
                if( !isNaN(tripId) )
                    this.onSelectTrip(tripId);
            })
            .catch( err => {
                this.alert.set(`Cannot get fixed route trip information (${err.message}). Are fixed routes enabled for ${tectransit.agency.name}?`);
            });
    }
    // public
    componentDidMount() {
        // The center of map is arithmetic average of all depots
        this.googleMap     = tectransit.getGoogleMap(document.getElementById("map")!,new MapOptions(MapOptions.getBounds(tectransit.getAgencyBoundary(),0.025)));
        this.mapBoundaries = (tectransit?.agency?.boundaries||[]).map( (boundary,ndx) => {
            return new window.google.maps.Polyline({
                map           : this.googleMap,
                path          : boundary,
                editable      : false,
                strokeColor   : FixedRoutes.strokeColors[ndx%FixedRoutes.strokeColors.length],
                strokeOpacity : 1.0,
                strokeWeight  : 3,
            });
        });
    }
    render() {
        return MenuItem.withMenuItem("Fixed Routes", (alert) => {
            this.alert = alert;
            const tdStyle = {
                margin       : 'auto',
                overflow     : 'hidden',
                textOverflow : 'ellipsis',
                width        : '350px',
                cursor       : 'pointer'
            };
            return (
                <div className="content">
                    <div className="wrapper">
                        <div className="section-card top-1">
                            <div className="section-card-wrap top-1">
                                <table width="100%">
                                    <tbody>
                                    <tr>
                                        <td style={{
                                            height:725,
                                            overflow:'auto',
                                            display:'block',
                                            verticalAlign:'top'
                                        }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            { (this.state.dbTrips.length>0) ? (
                                                                <select
                                                                    style={{width:'100%'}}
                                                                    onChange={(e)=>this.onSelectTrip(Number(e.target.value))}>
                                                                    {this.state.dbTrips.map( (t,tndx) => {
                                                                        return <option key={tndx} value={t._id}>{t.name}</option>;
                                                                    })}
                                                                </select>
                                                            ) : (
                                                                <div>n/a</div>
                                                            ) }
                                                        </td>
                                                    </tr>
                                                    {this.state.frTrip && (<>
                                                        <tr>
                                                            <td><strong>Service</strong></td>
                                                            <td colSpan={2}>{this.state.frTrip.service_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Stops</strong></td>
                                                            <td><strong>Arrival</strong></td>
                                                            <td><strong>Departure</strong></td>
                                                        </tr>
                                                        {this.state.frTrip.stops.map((stop,sndx) => {
                                                            const title      = `Stop #${stop._id} of trip #${this.state.frTrip!._id}`;
                                                            const background = (sndx%2) ?  '#ffffff' : '#dcdcdc';
                                                            return (
                                                                <tr key={`${this.state.frTrip!._id}_${sndx}`} style={{background}} title={title}>
                                                                    <td
                                                                        style   ={tdStyle}
                                                                        onClick ={(e) => {
                                                                            this.googleMap?.setZoom(18);
                                                                            this.googleMap?.panTo(stop);
                                                                        }}
                                                                    >
                                                                        <strong>{stop.name}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            size={5}
                                                                            defaultValue={stop.stop_time.arrival_time}
                                                                            onBlur={(e)=>{
                                                                                // Have to pass stop sequence because the same stop can happen multiple times in a trip
                                                                                return this.saveTrip({
                                                                                    stops : this.state.frTrip?.stops.map( s => {
                                                                                        if( (s._id===stop._id) && (s.stop_time.stop_sequence===stop.stop_time.stop_sequence) )
                                                                                            return {
                                                                                                ...s,
                                                                                                stop_time : {
                                                                                                    ...s.stop_time,
                                                                                                    arrival_time : e.target.value.trim()
                                                                                                }
                                                                                            }
                                                                                        return s;
                                                                                    })
                                                                                }).then( frTrip => {
                                                                                    if( frTrip )
                                                                                        this.alert.set(`Arrival time for stop '${stop.name}' is saved`,3000);
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            size={5}
                                                                            defaultValue={stop.stop_time.departure_time}
                                                                            onBlur={(e)=>{
                                                                                // Have to pass stop sequence because the same stop can happen multiple times in a trip
                                                                                return this.saveTrip({
                                                                                    stops : this.state.frTrip?.stops.map( s => {
                                                                                        if( (s._id!==stop._id) || (s.stop_time.stop_sequence!==stop.stop_time.stop_sequence) )
                                                                                            return s;
                                                                                        return {
                                                                                            ...s,
                                                                                            stop_time : {
                                                                                                ...s.stop_time,
                                                                                                departure_time : e.target.value.trim()
                                                                                            }
                                                                                        };
                                                                                    })
                                                                                }).then( frTrip => {
                                                                                    if( frTrip )
                                                                                        this.alert.set(`Departure time for stop '${stop.name}' is saved`,3000);
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>)}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{
                                            width:"80%",
                                            verticalAlign:'top'
                                        }}>
                                            <div id="map" style={{height:650}}></div>
                                            <br/>
                                            { this.state.frTrip && (
                                                <table width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <button
                                                                className="btn btn-theme"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    this.saveTrip({
                                                                        stops  : this.stopMarkers.map( (sm,smNdx) => {
                                                                            const stop = this.state.frTrip!.stops[smNdx];
                                                                            if( String(stop?._id)!==sm.getLabel() ) {
                                                                                this.alert.set(`Stop for marker #${smNdx} is not found`);
                                                                                return stop;
                                                                            }
                                                                            const position = sm.getPosition();
                                                                            if( !position ) {
                                                                                this.alert.set(`Marker #${smNdx} position is not found`);
                                                                                return stop;
                                                                            }
                                                                            return {
                                                                                ...stop,
                                                                                lat : position.lat(),
                                                                                lng : position.lng()
                                                                            };
                                                                        }),
                                                                        shapes : this.shapePolyline?.getPath().getArray().map((ll,ndx) => {
                                                                            // const shape = ... ??? how do we find the original shape?
                                                                            return {
                                                                                lat         : ll.lat(),
                                                                                lng         : ll.lng(),
                                                                                shape_id    : this.state.frTrip!.shape_id,
                                                                                ndx
                                                                            };
                                                                        })
                                                                    }).then( frTrip => {
                                                                        if( frTrip )
                                                                            this.alert.set(`Saved ${frTrip.stops.length} stops and ${frTrip.shapes.length} points of shape #${arrayDedupe(frTrip.shapes.map(s=>s.shape_id))}`,3000);
                                                                    });
                                                                }}
                                                            >
                                                                Save Trip
                                                            </button>
                                                        </td>
                                                        <td align='center'>
                                                            <button
                                                                className="btn btn-theme"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    this.downloadGtfs();
                                                                }}
                                                            >
                                                                Download GTFS
                                                            </button>
                                                        </td>
                                                        <td align="right">
                                                            <button
                                                                className="btn btn-theme btn-delete"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    this.resetShape();
                                                                }}
                                                            >
                                                                Reset Trip
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            )}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
}

export default FixedRoutes;