import * as CapacitorCore   from '@capacitor/core';
import {
    NetworkInterface,
    TecTransITPlugin,
    OpenTcpPortArgs,
    OpenWsArgs,
    OpenTcpPortResult,
    OpenWsResult
}                           from './types';

const tectransITPlugin = CapacitorCore.registerPlugin<CapacitorCore.Plugin & TecTransITPlugin>(
    'TecTransITPlugin',
    {
        web: () => import('./web').then(m => new m.TecTransITWeb()),
    }
);

export const buildConfig = (
) : Promise<Record<string,any>> => {
    return tectransITPlugin.buildConfig();
}
export const getNetworkInterfaces = (
) : Promise<Record<string,NetworkInterface>> => {
    return tectransITPlugin.getNetworkInterfaces();
}
export const openTcpPort = (args:OpenTcpPortArgs) : Promise<OpenTcpPortResult> => {
    if( !args.eventName )
        args.eventName = `tcp port ${args.port}`;
    return tectransITPlugin.openTcpPort(args).then(() => {
        const listenerFunc = (event:{
            remoteAddress : string,
            data          : any
        }) : void => {
            return args.onEvent(
                event.data,
                event.remoteAddress,
                (data:any) => {
                    return tectransITPlugin.sendSocket({
                        port          : args.port,
                        remoteAddress : event.remoteAddress,
                        data
                    });
                },
                () => {
                    return tectransITPlugin.closeSocket({
                        port          : args.port,
                        remoteAddress : event.remoteAddress
                    });
                }
            );
        }
        return tectransITPlugin.addListener(args.eventName!,listenerFunc).then((handle:CapacitorCore.PluginListenerHandle) => {
            return {
                port : args.port,
                handle
            };
        });
    });
}
export const closeTcpPort = (openTcpPortResult:OpenTcpPortResult) : Promise<void> => {
    return tectransITPlugin.closeTcpPort(openTcpPortResult).then(() => {
        return openTcpPortResult.handle.remove();
    });
}
export const openWs = (args:OpenWsArgs) : Promise<OpenWsResult> => {
    if( !args.eventName )
        args.eventName = `${args.wsAddress}@${Date.now()}`;
    return tectransITPlugin.openWs(args).then(() => {
        const listenerFunc = (event:any) => {
            return args.onEvent(event);
        };
        return tectransITPlugin.addListener(args.eventName!,listenerFunc).then((handle:CapacitorCore.PluginListenerHandle) => {
            return {
                eventName : args.eventName!,
                handle
            };
        });
    });
}
export const closeWs = ( openWsResult:OpenWsResult, closeTimeout:number=1000 ) : Promise<void> => {
    return tectransITPlugin.closeWs({eventName:openWsResult.eventName,closeTimeout}).then(() => {
        return openWsResult.handle.remove();
    });
}
export const sendWs = (openWsResult:OpenWsResult, data:string) : Promise<void> => {
    return tectransITPlugin.sendWs({eventName:openWsResult.eventName,data});
}
export const sendPingWs = (openWsResult:OpenWsResult, data:string) : Promise<void> => {
    return tectransITPlugin.sendPingWs({eventName:openWsResult.eventName,data});
}
export const sendPongWs = (openWsResult:OpenWsResult, data:string) : Promise<void> => {
    return tectransITPlugin.sendPongWs({eventName:openWsResult.eventName,data});
}