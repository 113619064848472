import React                                    from 'react';
import jsonParse                                from 'shared/utils/jsonParse';

import * as ApcType                             from 'utils/APC';
import * as TecTransITPluginTypes               from 'plugins/TecTransIT/types';
import * as TecTransITPlugin                    from 'plugins/TecTransIT';

import * as MenuItem                            from 'components/MenuItem';
import { Capacitor }                            from '@capacitor/core';
import { Device }                               from '@capacitor/device';

interface Props {
}

export default class PluginTest extends React.Component<Props> {

    // Used by above components
    // @ts-expect-error
    public props                        : Props;
    public pluginBuildConfig            : (Record<string,any>|undefined);
    public openTcpPortResult            : (TecTransITPluginTypes.OpenTcpPortResult|undefined);
    public apcMessage?                  : string;

    // public
    componentDidMount() {
        // Let's start listening for APC device messages
        const onApcData = ( data:any ) => {
            const packet = jsonParse(data,{}) as ApcType.Packet;
            if( !packet.sn || !packet.type )
                throw Error(`Got wrong data from APC: ${data}`);
            console.log("Got packet",data);
        };
        TecTransITPlugin.openTcpPort({port:ApcType.tcpPort,onEvent:onApcData})
            .then( openTcpPortResult => {
                console.log(`Opened TCP port ${ApcType.tcpPort}`,JSON.stringify(openTcpPortResult));
                this.openTcpPortResult = openTcpPortResult;
            })
            .catch( err => {
                const lastReload = Number(sessionStorage.getItem('lastCapacitorHackingReload'));
                if( (Date.now()-lastReload)>(1000*60*5) ) {
                    // If we've reloaded the page in the last 5 minutes, don't keep reloading
                    // Just report an error
                    sessionStorage.setItem('lastCapacitorHackingReload',String(Date.now()));
                    window.location.reload();
                }
                else {
                    this.apcMessage = `Cannot open TCP port ${ApcType.tcpPort} ${err.message}`;
                }
            });
        TecTransITPlugin.buildConfig()
            .then( buildConfig => {
                console.log('buildConfig',JSON.stringify(buildConfig));
                this.pluginBuildConfig = buildConfig;
            })
            .catch( err => {
                this.apcMessage = `Cannot get build config ${err.message}`;
            });
    }
    componentWillUnmount() {
        if( this.openTcpPortResult ) {
            TecTransITPlugin.closeTcpPort(this.openTcpPortResult)
                .then( res => {
                    console.log(`Closed TCP port ${ApcType.tcpPort}`,res);
                })
                .catch( err => {
                    console.error(`Cannot close TCP port ${ApcType.tcpPort}`,err);
                });
            this.openTcpPortResult = undefined;
        }
    }
    render() {
        return (
            <MenuItem.MenuItem title="PluginTest">
                {MenuItem.withAlert((alert) => {
                    return (<table>
                        <tbody>
                        <tr>
                            <td>APC Message</td>
                            <td>{JSON.stringify(this.apcMessage||'n/a')}</td>
                        </tr>
                        <tr>
                            <td>openTcpPortResult</td>
                            <td>{JSON.stringify(this.openTcpPortResult||{})}</td>
                        </tr>
                        <tr>
                            <td>buildConfig</td>
                            <td>{JSON.stringify(this.pluginBuildConfig||{})}</td>
                        </tr>
                        <tr>
                            <td>Capacitor.Plugins</td>
                            <td>{JSON.stringify(Capacitor.Plugins||{})}</td>
                        </tr>
                        <tr>
                            <td>Device</td>
                            <td>{JSON.stringify(Device||{})}</td>
                        </tr>
                    </tbody>
                    </table>);
                })}
            </MenuItem.MenuItem>
        );
    }
}
