import React                from 'react';
import { Link }             from 'react-router-dom';
import Modal                from "react-bootstrap/Modal";

import actions              from 'redux/actions';

import consts               from 'shared/consts';
import * as OrderType       from 'shared/types/Order';
import * as VehicleType     from 'shared/types/Vehicle';
import * as UserType        from 'shared/types/User';
import * as ManifestType    from 'shared/types/Manifest';
import * as WSPayloadType   from 'shared/types/WSPayload';
import * as RouteType       from 'shared/types/Route';
import rehash               from 'shared/utils/rehash';
import dayjs                from 'shared/utils/day-timezone';

import Alert                from 'utils/Alert';
import getApiPromise        from 'utils/getApiPromise';
import usStates             from 'utils/usStates';
import tectransit           from 'utils/TecTransit';
import * as googleMapsLinks from 'utils/googleMapsLinks';
import * as images          from 'images/';
import * as MenuItem        from 'components/MenuItem';

interface VehicleProps {
    fixedRoutes     : string[];
    drivers         : UserType.User[];
    vehicle         : WSPayloadType.Vehicle.Hydrated;
    orders          : OrderType.Hydrated<OrderType.Order<number>>[];
    onDeleteDriver  : (driver : (UserType.User|undefined)) => any;
    onAddDriver     : (driver : (UserType.User|undefined)) => any;
    onUpdate        : (delta  : Partial<VehicleType.Vehicle>) => any;
    onArchive?      : () => any;
    onCreate?       : () => any;
}
class Vehicle extends React.Component {
    private unsubscribe? : ()=>void;
    // @ts-expect-error
    public  props : VehicleProps;
    public  state : {
        driversDom?      : any;
    } = {
    };
    componentDidMount() {
        const getState = () => {
            const vehiclesByDriverId  = tectransit.agency.vehicles_by_driver_id;
            const existingDrivers     = this.props.drivers.filter(d=>(Number(vehiclesByDriverId[d._id])===this.props.vehicle._id));
            const potentialDrivers    = this.props.drivers.filter(d=>(Number(vehiclesByDriverId[d._id])!==this.props.vehicle._id));
            const existingDriversDom  = existingDrivers.map( d => (
                <tr key={`${d._id}`}>
                    <td><Link to={`/Manager/Passenger/${d._id}`}>{d.name || d.email || `Driver #${d._id}`}</Link></td>
                    <td><images.DeleteDriver onClick={()=>this.props.onDeleteDriver(d)}/></td>
                </tr>
            ));
            const potentialDriversDom = (
                <tr key={`${this.props.vehicle._id}_potential_drivers`}>
                    <td>
                        <select className="input-theme input-theme-min" id={`${this.props.vehicle._id}_potential_drivers`}>{potentialDrivers.map( d => (
                            <option key={d._id} value={d._id}>
                                {d.name || d.email || `User #${d._id}`} ({vehiclesByDriverId[d._id] ? `driving vehicle #${vehiclesByDriverId[d._id]}` : `not driving`})
                            </option>
                        ))}
                        </select>
                    </td>
                    <td>
                        <images.AddDriver onClick={()=>{
                            const pd = (document.getElementById(`${this.props.vehicle._id}_potential_drivers`) as HTMLSelectElement);
                            return this.props.onAddDriver(potentialDrivers.find(d=>(String(d._id)===pd?.value)));
                        }}/>
                    </td>
                </tr>
            );
            return {
                driversDom : (<table>
                    <tbody>
                        { potentialDrivers.length ? potentialDriversDom : (<tr key={"no_drivers_to_add"} className="nodriver" role="alert"><td colSpan={2}>No more drivers to add</td></tr>) }
                        { existingDrivers.length  ? existingDriversDom  : (<tr key={"no_drivers"} className="nodriver" role="alert"><td colSpan={2}>No drivers</td></tr>)  }
                    </tbody>
                </table>)
            };
        };
        this.setState(getState());
        this.unsubscribe = tectransit.store.subscribe(() => {
            this.setState(getState());
        });
    }
    componentWillUnmount() {
        if( this.unsubscribe ) {
            this.unsubscribe();
            delete this.unsubscribe;
        }
    }
    render() {
        if( !this.state?.driversDom )
            return (<></>);
        const odometer = Math.ceil((this.props.vehicle.odometer_meters||0)/consts.meters_in_mile);
        const reportedLocation  = this.props.vehicle.reported_location;
        const isAccuracyLow     = (reportedLocation?.accuracy||1000)>tectransit.agency.gps_accuracy_meters;
        const isLocationStale   = ((Date.now()/1000)-tectransit.agency.max_lateness_seconds)>(reportedLocation?.seconds||0);
        const vehItems = {
            'Fleet #'           : ((typeof this.props.onCreate==='function') ? (
                <>
                    <input
                        className   = "input-theme input-theme-min"
                        style       = {{width:'77%',display:'unset'}}
                        type        = "text"
                        defaultValue= {'(auto)'}
                        onChange    = {(e)=>this.props.onUpdate({_id:Number(e.target.value)})}
                    />
                </>) : (<>{this.props.vehicle._id}</>)),
            'Status'            : (<>
                <strong>{this.props.vehicle.is_online ? 'Online' : 'Offline'}</strong> since <strong>{dayjs(this.props.vehicle.is_online_changed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</strong>
                </>),
            'Location'          : reportedLocation
                ? <>{googleMapsLinks.getPlace(reportedLocation,undefined)}, accuracy is <span style={{color:(isAccuracyLow?'#ff0000':'#6c757d')}}>{reportedLocation.accuracy?.toFixed()??'n/a'}m</span> as of <span style={{color:(isLocationStale?'#ff0000':'#000000')}}>{dayjs(reportedLocation.seconds*1000).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</span></>
                : 'n/a',
            'Driver'            : this.state.driversDom,
            'Depot'             : (
                <select
                    className   ="input-theme input-theme-min"
                    defaultValue={this.props.vehicle.depot_name||''}
                    onChange    ={(e)=>this.props.onUpdate({depot_name:e.target.value.trim()})}>
                    {tectransit.agency.depots.map((d,ndx)=><option key={ndx} value={d.name}>{d.name}</option>)}
                </select>
            ),
            'Odometer'          : (
                <>
                    <input
                        className   = "input-theme input-theme-min"
                        style       = {{width:'77%',display:'unset'}}
                        type        = "number"
                        min         = {odometer/2}
                        max         = {odometer*2}
                        defaultValue= {odometer}
                        onChange    = {(e)=>this.props.onUpdate({odometer_meters:Number(e.target.value)*consts.meters_in_mile})}
                    />&nbsp;miles
                </>
            )
        } as Record<string,any>;
        if( this.props.vehicle.archived_at )
            vehItems['Archived At'] = (<>{dayjs(this.props.vehicle.archived_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</>);
        if( (this.props.vehicle.type||'Bus')==='Bus' ) {
            Object.assign(vehItems,{
                [`Seating+Standing Positions`] : (
                    <input
                        type        = "text"
                        className   = "input-theme input-theme-min"
                        placeholder = "max number of seating positions"
                        defaultValue= {this.props.vehicle.seats||0}
                        size        = {3}
                        maxLength   = {3}
                        onBlur      = {(e)=>this.props.onUpdate({seats:Number(e.target.value.trim())})}/>
                ),
                'Wheelchair Positions' : (
                    <input
                        type        = "text"
                        className   = "input-theme input-theme-min"
                        placeholder = "max number of wheelchair positions"
                        defaultValue= {this.props.vehicle.wheelchairs||0}
                        size        = {3}
                        maxLength   = {3}
                        onBlur      = {(e)=>this.props.onUpdate({wheelchairs:Number(e.target.value.trim())})}/>
                ),
                'License Plate'  : (
                    <input
                        type        ="text"
                        placeholder ="license plate"
                        className   ="input-theme input-theme-min"
                        defaultValue={this.props.vehicle.license_plate||''}
                        size        ={10}
                        maxLength   ={10}
                        onBlur      ={(e)=>this.props.onUpdate({license_plate:e.target.value.trim()})}/>
                ),
                'License State' : (
                    <select
                        defaultValue={this.props.vehicle.license_state||'CA'}
                        className   ="input-theme input-theme-min"
                        onChange    ={(e)=>this.props.onUpdate({license_state:e.target.value.trim()})}>
                        {usStates.map(s=><option key={s} value={s}>{s}</option>)}
                    </select>
                ),
                'Make, Model & Year' : (
                    <input
                        type        = "text"
                        placeholder = "make, model, year"
                        className   = "input-theme input-theme-min"
                        defaultValue= {this.props.vehicle.make_model_year||''}
                        size        = {25}
                        maxLength   = {25}
                        onBlur      = {(e)=>this.props.onUpdate({make_model_year:e.target.value.trim()})}/>
                ),
                'VIN number' : (
                    <input
                        type        = "text"
                        className   = "input-theme input-theme-min"
                        defaultValue= {this.props.vehicle.vin_number||''}
                        size        = {25}
                        maxLength   = {25}
                        onBlur      = {(e)=>this.props.onUpdate({vin_number:e.target.value.trim()})}/>
                ),
                [tectransit.agency.unavailability_is_checkbox?'Unavailable':'Unavailability'] : (tectransit.agency.unavailability_is_checkbox ? (
                    <input
                        type        = "checkbox"
                        name        = "unavailability_reason"
                        defaultChecked={this.props.vehicle.unavailability_reason!==''}
                        onChange    = {(e:React.ChangeEvent<HTMLInputElement>)=>this.props.onUpdate({unavailability_reason:e.target.checked?`unavailable as of ${dayjs().tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}`:''})}
                    />
                ) : (
                    <input
                        type        = "text"
                        className   = "input-theme input-theme-min"
                        defaultValue= {this.props.vehicle.unavailability_reason||''}
                        size        = {50}
                        maxLength   = {90}
                        onBlur      = {(e)=>this.props.onUpdate({unavailability_reason:e.target.value.trim()})}/>
                ))
            });
            if( tectransit.agency.on_demand_enabled ) {
                vehItems["Orders"]    = this.props.orders.map(order=>`#${order._id}`).join(', ')||'(none)';
                vehItems['On Demand'] = (
                    <input
                        type        = "checkbox"
                        name        = "on_demand_enabled"
                        defaultChecked={this.props.vehicle.on_demand_enabled}
                        onChange    = {(e:React.ChangeEvent<HTMLInputElement>)=>this.props.onUpdate({on_demand_enabled:e.target.checked})}
                    />
                );
            }
            if( tectransit.agency.fixed_route_enabled ) {
                vehItems['Fixed Route Name'] = (
                    <select
                        title       = 'Used only if the fixed route name is not defined by Manifest'
                        className   = "input-theme input-theme-min"
                        defaultValue= {this.props.vehicle.fixed_route_name}
                        onChange    = {(e)=>this.props.onUpdate({fixed_route_name:e.target.value.trim()})}>
                        {<option value="">(none)</option>}
                        {this.props.fixedRoutes.map((fl,ndx) =><option key={ndx} value={fl}>{fl}</option>)}
                    </select>
                );
            }
        }
        return [
            Object.entries(vehItems).map(([name,dom],ndx) => {
                return (<div key={ndx} id={`vehicle_${this.props.vehicle._id}_${name.replace(/[^a-z0-9_]/gi,'_').toLowerCase()}`}className="veh-item">
                    <div className="veh-name">{name}</div>
                    <div className="veh-content">{dom}</div>
                </div>);
            }),
            (typeof this.props.onArchive==='function') && (<div key={'archive'} className="veh-item">
                <div className="veh-name"></div>
                <div className="veh-content">
                    <button
                        className   = "btn btn-theme btn-min btn-delete"
                        onClick     = {(e)=>{
                            if( window.confirm(`Do you really want to archive vehicle #${this.props.vehicle._id}? If you go ahead then you will have to contact technical support to restore the vehicle.`) )
                                this.props.onArchive!();
                        }}>
                        Archive
                    </button>
                </div>
            </div>),
            (typeof this.props.onCreate==='function') && (<div key={'create'} className="veh-item">
                <div className="veh-name"></div>
                <div className="veh-content">
                    <button
                        className   = "btn btn-theme btn-min"
                        onClick     = {(e)=>this.props.onCreate!()}>
                        Create
                    </button>
                </div>
            </div>),
            <div key={`$divider_${this.props.vehicle._id}`} className="veh-end"/>
        ];
    }
}

export default class VehiclesMenuItem extends React.Component {
    private alert : Alert = new Alert();
    private newVehicle : VehicleType.Vehicle = {
        odometer_meters     : 0,
        seats               : 0,
        wheelchairs         : 0,
        depot_name          : (tectransit.agency.depots[0]?.name||''),
        license_state       : 'CA',
        unavailability_reason : '',
    } as VehicleType.Vehicle;
    public  state : {
        wsPayload           : WSPayloadType.WSPayload.Hydrated;
        drivers             : UserType.User[];
        fixedRoutes         : string[];
        manifestsByVid      : Record<number,ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>>;
        ordersByVid         : Record<number,OrderType.Hydrated<OrderType.Order<number>>[]>;
        reassignedVid       : number;
        reassignedDelta     : (Partial<VehicleType.Vehicle>|undefined);
    } = {
        wsPayload             : {
            seconds      : (Date.now()/1000),
            vehiclesById : {},
            ordersById   : {},
            usersById    : {}
        },
        drivers             : [],
        fixedRoutes         : [],
        manifestsByVid      : {},
        ordersByVid         : {},
        reassignedVid       : -1,
        reassignedDelta     : undefined
    }
    private getDeleteDriverPromise( vehicle:WSPayloadType.Vehicle.Hydrated,driver_id:number ) {
        return getApiPromise('/api/manager/vehicles/drivers',"DELETE",undefined,{vehicle_id:vehicle._id,user_id:driver_id});
    }
    private getPostDriverPromise( vehicle:WSPayloadType.Vehicle.Hydrated,driver_id:number ) {
        return getApiPromise('/api/manager/vehicles/drivers',"POST",undefined,{vehicle_id:vehicle._id,user_id:driver_id});
    }
    private onDeleteVehicleDriver( vehicle:WSPayloadType.Vehicle.Hydrated, driver:(UserType.User|undefined) ) {
        if( !driver ) {
            this.alert.set(`Driver is empty`);
            return;
        }
        return this.getDeleteDriverPromise(vehicle,driver._id)
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||`result is empty`);
                tectransit.store.dispatch(actions.agency__delete_driver({user_id:driver._id}));
            })
            .catch( err => {
                this.alert.set(`Cannot delete driver (${err.message})`);
            });
    }
    private onAddVehicleDriver( vehicle:WSPayloadType.Vehicle.Hydrated, driver:(UserType.User|undefined) ) {
        if( !driver ) {
            this.alert.set(`New driver is empty`);
            return;
        }
        const existingDriverIds = Object.entries(tectransit.agency.vehicles_by_driver_id)
            .filter(([driver_id,vehicle_id])=>(vehicle_id===vehicle._id))
            .map(([driver_id,vehicle_id])=>Number(driver_id));
        if( existingDriverIds.includes(driver._id) ) {
            this.alert.set(`New driver should not be among existing drivers`);
            return;
        }
        // Let's allow only one driver assigned to vehicle. This means all existing drivers
        // need to be deleted when the new one is added
        return Promise.all([...existingDriverIds.map(did=>this.getDeleteDriverPromise(vehicle,did)),this.getPostDriverPromise(vehicle,driver._id)])
            .then( results => {
                const failures  = results.filter(res=>res.err);
                const successes = results.filter(res=>!res.err);
                if( failures.length>0 )
                    throw Error(failures.map(res=>res.err).join(";"));
                if( successes.length>0 ) {
                    // TODO:
                    // Try to use `agency__add_driver` action here
                    const vehicles_by_driver_id = {
                        ...Object.entries(tectransit.agency.vehicles_by_driver_id).reduce((acc,[driver_id,vehicle_id])=>{
                            if( vehicle_id!==vehicle._id )
                                acc[Number(driver_id)] = vehicle_id;
                            return acc;
                        },{} as Record<number,number>),
                        [driver._id] : vehicle._id
                    };
                    tectransit.store.dispatch(actions.agency__update({vehicles_by_driver_id}));
                }
            })
            .catch( err => {
                this.alert.set(`Cannot add driver (${err.message})`);
            });
    }
    private onSaveVehicle( vehicle:WSPayloadType.Vehicle.Hydrated, delta:Partial<VehicleType.Vehicle>, query:Record<string,any>={} ) {
        return getApiPromise('/api/manager/vehicle','PUT',delta,{id:vehicle._id,...query})
            .then( res => {
                if( !res || res.err )
                    throw Error(res.err||'result is empty');
                if( Array.isArray(res.messages) )
                    this.alert.set(res.messages.join(';'));
                const newVehicle = {
                    ...vehicle,
                    ...delta
                };
                this.setState({
                    wsPayload : {
                        ...this.state.wsPayload,
                        vehiclesById : {
                            ...this.state.wsPayload.vehiclesById,
                            [vehicle._id] : newVehicle
                        }
                    },
                    reassignedVid       : -1,
                    reassignedDelta     : undefined
                });
                if( newVehicle.archived_at ) {
                    // If vehicle is archived then throw out all references to it from
                    // tectransit.agency.vehicles_by_driver_id
                    const vehicles_by_driver_id = Object.entries(tectransit.agency.vehicles_by_driver_id)
                        .filter(([driver_id,vehicle_id])=>(vehicle_id!==vehicle._id))
                        .reduce((acc,[driver_id,vehicle_id])=>Object.assign(acc,{[driver_id]:vehicle_id}),{});
                    tectransit.store.dispatch(actions.agency__update({vehicles_by_driver_id}));
                }
                return res;
            })
            .catch( err => {
                this.alert.set(`Cannot save vehicle (${err.message})`);
            });
    }
    private onUpdateVehicle( vehicle:WSPayloadType.Vehicle.Hydrated, delta:Partial<VehicleType.Vehicle> ) {
        try {
            if( !(vehicle._id in this.state.wsPayload.vehiclesById) )
                throw Error(`Cannot find vehicle with id #${vehicle._id}`);
            // Just do confirmations. All real input validation will happen on the server
            if( (delta.unavailability_reason||'').trim()!=='' || delta.archived_at ) {
                if( vehicle.fixed_route_name ) {
                    if( RouteType.getMeters(vehicle.route)>tectransit.agency.gps_accuracy_meters ) {
                        // This is a fixed route vehicle that is currently assigned to a trip
                        // Making it unavailable or archived requires a trip reassignment
                        this.setState({
                            reassignedVid   : vehicle._id,
                            reassignedDelta : delta
                        });
                        return;
                    }
                }
                else {
                    if( (this.state.ordersByVid[vehicle._id]||[]).length>0 ) {
                        // This is an on-demand vehicle that has some orders assigned to it.
                        // Making it unavailable or archived requires order re-assignment.
                        this.setState({
                            reassignedVid   : vehicle._id,
                            reassignedDelta : delta
                        });
                        return;
                    }
                }
            }
            return this.onSaveVehicle(vehicle,delta);
        }
        catch( err ) {
            this.alert.set(`Setting is invalid (${(err as Error).message})`);
        }
    }
    private onCreateVehicle( vehicle:VehicleType.Vehicle ) {
        return getApiPromise<VehicleType.Vehicle>('/api/manager/vehicle','POST',vehicle)
            .then( newVehicle => {
                if( !newVehicle || newVehicle.err )
                    throw Error(newVehicle.err||'result is empty');
                this.setState({
                    wsPayload : {
                        ...this.state.wsPayload,
                        vehiclesById : {
                            ...this.state.wsPayload.vehiclesById,
                            [newVehicle._id] : newVehicle
                        }
                    }
                });
                if( newVehicle._id!==vehicle._id ) {
                    // If vehicle id has changed, then replace all references to old _id with the new_id
                    // in tectransit.agency.vehicles_by_driver_id
                    const vehicles_by_driver_id = Object.entries(tectransit.agency.vehicles_by_driver_id)
                        .map(([driver_id,vehicle_id])=>([driver_id,(vehicle_id===newVehicle._id)?newVehicle._id:vehicle_id]))
                        .reduce((acc,[driver_id,vehicle_id])=>Object.assign(acc,{[driver_id]:vehicle_id}),{});
                    tectransit.store.dispatch(actions.agency__update({vehicles_by_driver_id}));
                }
                return newVehicle;
            })
            .catch( err => {
                this.alert.set(`Cannot create vehicle (${err.message})`);
            });
    }
    private getReassignRouteModal( vehicle:WSPayloadType.Vehicle.Hydrated, delta:Partial<VehicleType.Vehicle> ) {
        const unavailabilityByVid  = {} as Record<number,string>;
        const substitutionVehicles = Object.values(this.state.wsPayload.vehiclesById)
            .filter( v => {
                if( v._id===vehicle._id ) {
                    unavailabilityByVid[v._id] = `Cannot substitute itself`
                    return false;
                }
                if( v.unavailability_reason ) {
                    unavailabilityByVid[v._id] = `Unavailable (${v.unavailability_reason})`;
                    return false;
                }
                if( v.archived_at ) {
                    unavailabilityByVid[v._id] = `Archived`;
                    return false;
                }
                if( !v.reported_location ) {
                    unavailabilityByVid[v._id] = `Current location is unknown`;
                    return false;
                }
                if( vehicle.fixed_route_name ) {
                    if( !v.fixed_route_name ) {
                        unavailabilityByVid[v._id] = `Not a fixed-route vehicle`;
                        return false;
                    }
                    if( RouteType.getMeters(v.route)>tectransit.agency.gps_accuracy_meters ) {
                        unavailabilityByVid[v._id] = `Already running route '${v.fixed_route_trip?.name||'??'}'`;
                        return false;
                    }
                }
                else {
                    if( v.fixed_route_name ) {
                        unavailabilityByVid[v._id] = `Not an on-demand vehicle`;
                        return false;
                    }
                }
                return true;
            })
            .sort( (v1,v2) => {
                // Sort them so that the vehicles with the fewest orders are at the top
                return (this.state.ordersByVid[v1._id]||[]).length-(this.state.ordersByVid[v2._id]||[]).length;
            });
        const unavailabilityTitle = Object.entries(unavailabilityByVid)
            .map(([vid,unavailability]) =>{
                return `#${vid}: ${unavailability}`;
            })
            .join('\n');
        if( vehicle.fixed_route_name ) {
            // This is a fixed-route vehicle
            return (
                <Modal
                    centered        = {true}
                    dialogClassName = "modal-90w"
                    aria-labelledby = "order-modal"
                    show            = {true}
                    onEntered       = {(modal) => {
                        window.scrollTo(0,0);
                    }}
                    onHide          = {() => {
                        this.setState({
                            reassignedVid   : -1,
                            reassignedDelta : undefined
                        });
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Fixed Route Trip of vehicle #{vehicle._id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Vehicle #{vehicle._id} now has <strong>{vehicle.fixed_route_trip?.name||'n/a'}</strong> route assigned to it.
                        </p>
                        { (substitutionVehicles.length>0) ? (
                            <React.Fragment>
                                <p>
                                    There are <strong title={unavailabilityTitle}>{substitutionVehicles.length}</strong> vehicle(s)
                                    that can potentially substitute vehicle #<strong>{vehicle._id}</strong>:
                                </p>
                                <select
                                    id          = "substitutionVehicleId"
                                    className   = "input-theme input-theme-min"
                                >
                                    {substitutionVehicles.map( v => {
                                        const vm            = this.state.manifestsByVid[v._id];
                                        return (<option key={v._id} value={v._id}>Vehicle #{v._id} (on {v.fixed_route_name},{vm?'has VM':'no VM'})</option>);
                                    })}
                                </select>
                                <button
                                    className   = "btn-theme btn-small"
                                    style       = {{margin:'auto'}}
                                    onClick     = {(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const substitutionVehicleId = parseInt((document.getElementById('substitutionVehicleId') as HTMLSelectElement).value||'-1');
                                        return this.onSaveVehicle(vehicle,delta,{substitutionVehicleId});
                                    }}
                                >
                                    Substitute vehicle #{vehicle._id}
                                </button>
                                <p>
                                    Alternatively you can save your changes without substituting the vehicle for <b>{vehicle.fixed_route_trip?.name}</b>.
                                    If you proceed, <b> {vehicle.fixed_route_trip?.name} </b> will become abandoned.
                                </p>
                                <button
                                    className   = "btn-theme btn-small"
                                    style       = {{margin:'auto'}}
                                    onClick     = {(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if( !window.confirm(`You have ${substitutionVehicles.length} vehicle(s) that can substite vehicle #${vehicle._id}. Do you really want to leave the orders unassigned?`) )
                                            return;
                                        return this.onSaveVehicle(vehicle,delta);
                                    }}
                                >
                                    Leave <b> {vehicle.fixed_route_trip?.name} </b> abandoned.
                                </button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <p>
                                    There are <strong title={unavailabilityTitle}>no</strong> vehicles that can substitute
                                    vehicle #<strong>{vehicle._id}</strong>. You can still save your changes by leaving
                                    route <b> {vehicle.fixed_route_trip?.name} </b> abandoned.
                                </p>
                                <button
                                    className   = "btn-theme btn-small"
                                    style       = {{margin:'auto'}}
                                    onClick     = {(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return this.onSaveVehicle(vehicle,delta);
                                    }}
                                >
                                    Leave&nbsp;<b>{vehicle.fixed_route_trip?.name}</b>&nbsp;abandoned.
                                </button>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                </Modal>
            );
        }
        else {
            // This is an on-demand vehicle
            const vehicleOrders = this.state.ordersByVid[vehicle._id]||[];
            return (
                <Modal
                    centered        = {true}
                    dialogClassName = "modal-90w"
                    aria-labelledby = "order-modal"
                    show            = {true}
                    onEntered       = {(modal) => {
                        window.scrollTo(0,0);
                    }}
                    onHide          = {() => {
                        this.setState({
                            reassignedVid   : -1,
                            reassignedDelta : undefined
                        });
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Orders of vehicle #{vehicle._id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Vehicle #{vehicle._id} has <strong>{vehicleOrders.length}</strong> orders assigned to it.
                        </p>
                        { (vehicleOrders.length>0) && (
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <td><strong>#</strong></td>
                                        <td><strong>Passenger</strong></td>
                                        <td><strong>Status</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vehicleOrders.map( order => {
                                        return (
                                            <tr key={order._id}>
                                                <td>{order._id}</td>
                                                <td><Link to={`/Dispatcher/User/${order.user_id}`}>{order.user?.name}</Link></td>
                                                <td>{order.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) }
                        { (substitutionVehicles.length>0) ? (
                            <React.Fragment>
                                <p>
                                    There are <strong title={unavailabilityTitle}>{substitutionVehicles.length}</strong> vehicle(s)
                                    that can potentially substitute vehicle #<strong>{vehicle._id}</strong>:
                                </p>
                                <select
                                    id          = "substitutionVehicleId"
                                    className   = "input-theme input-theme-min"
                                >
                                    {substitutionVehicles.map( v => {
                                        const vehicleOrders = this.state.ordersByVid[v._id]||[];
                                        const vm            = this.state.manifestsByVid[v._id];
                                        return (<option key={v._id} value={v._id}>Vehicle #{v._id} ({vehicleOrders.length} order(s),{vm?'has VM':'no VM'})</option>);
                                    })}
                                </select>
                                <button
                                    className   = "btn-theme btn-small"
                                    style       = {{margin:'auto'}}
                                    onClick     = {(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const substitutionVehicleId = parseInt((document.getElementById('substitutionVehicleId') as HTMLSelectElement).value||'-1');
                                        return this.onSaveVehicle(vehicle,delta,{substitutionVehicleId});
                                    }}
                                >
                                    Substitute vehicle #{vehicle._id}
                                </button>
                                <p>
                                    Alternatively you can save your changes without substituting the vehicle for these orders.
                                    If you proceed, you will leave these orders <Link to={`/Dispatcher/Orders/Unassigned`}>unassigned
                                    </Link> and will have to manually re-assign the orders one-by-one using the <Link to={`/Dispatcher/Routes`}>Routes
                                    </Link> page.
                                </p>
                                <button
                                    className   = "btn-theme btn-small"
                                    style       = {{margin:'auto'}}
                                    onClick     = {(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if( !window.confirm(`You have ${substitutionVehicles.length} vehicle(s) that can substite vehicle #${vehicle._id}. Do you really want to leave the orders unassigned?`) )
                                            return;
                                        return this.onSaveVehicle(vehicle,delta);
                                    }}
                                >
                                    Leave {vehicleOrders.length} order(s) unassigned
                                </button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <p>
                                    There are <strong title={unavailabilityTitle}>no</strong> vehicles that can substitute
                                    vehicle #<strong>{vehicle._id}</strong>. You can still save your changes by leaving these
                                    orders <Link to={`/Dispatcher/Orders/Unassigned`}>unassigned</Link>. If you proceed, you will
                                    have to manually re-assign the orders one-by-one using the <Link to={`/Dispatcher/Routes`}>Routes
                                    </Link> page.
                                </p>
                                <button
                                    className   = "btn-theme btn-small"
                                    style       = {{margin:'auto'}}
                                    onClick     = {(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return this.onSaveVehicle(vehicle,delta);
                                    }}
                                >
                                    Leave {vehicleOrders.length} order(s) unassigned
                                </button>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                </Modal>
            );
        }
    }
    componentDidMount() {
        Promise.all([
            getApiPromise<WSPayloadType.WSPayload.Dehydrated>('/api/manager/vehicles','GET')
                .then( wsPayload => {
                    if( !wsPayload || wsPayload.err )
                        this.alert.set(`Cannot get vehicles (${wsPayload?.err||'unknown error'})`);
                    return WSPayloadType.WSPayload.hydrate(wsPayload);
                }),
            getApiPromise<UserType.Dehydrated[]>('/api/manager/users?inRoles=Driver')
                .then( users => {
                    if( !users || users.err )
                        this.alert.set(`Cannot get drivers (${users?.err||'unknown error'})`);
                    return users.map(UserType.hydrate);
                }),
            // Ask for on demand manifests only if on demand is enabled
            (tectransit.agency.on_demand_enabled ? getApiPromise<ManifestType.VehicleManifest.Dehydrated<string>[]>(`/api/dispatcher/ondemand/vehicleManifests`,"GET",undefined,{date:dayjs().tz(tectransit.agency.time_zone).format('YYYY-MM-DD')}) : Promise.resolve([]))
                .then( dehydratedVehicleManifests => {
                    if( !dehydratedVehicleManifests || dehydratedVehicleManifests.err )
                        throw Error(`Cannot get vehicle manifests (${dehydratedVehicleManifests?.err||'unknown error'})`);
                    return dehydratedVehicleManifests.reduce( (acc,vm) => {
                        const usersById = rehash(vm.usersById,user=>UserType.hydrate(user));
                        const hydrated  = ManifestType.VehicleManifest.hydrate(
                            vm,
                            rehash(vm.ordersById,order=>OrderType.hydrate(order,usersById)),
                            vm.fixedRouteStopsById||{}
                        );
                        acc[hydrated.vehicle_id!] = hydrated;
                        return acc;
                    },{} as Record<number,ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>>);
                }),
            // Ask for fixed routes only if they are enabled
            (tectransit.agency.fixed_route_enabled ? getApiPromise<string[]>('/api/public/fixedRoute/routeNames') : Promise.resolve([]))
                .then( fixedRoutes => {
                    if( !fixedRoutes || fixedRoutes.err )
                        this.alert.set(`Cannot get fixed routes (${fixedRoutes?.err||'unknown error'})`);
                    return fixedRoutes;
                })
        ]).then( ([wsPayload,drivers,manifestsByVid,fixedRoutes]) => {
            return this.setState({
                wsPayload,
                drivers,
                manifestsByVid,
                // In case of fixed route only the wsPayload will not have any orders. Therefore
                // `ordersById` will be empty and `this.onUpdateVehicle` will never set
                // `reassignedVid` in the state.
                // In other words in case of fixed route only everything will work as designed.
                ordersByVid: Object.values(wsPayload.vehiclesById).reduce( (acc,vehicle) => {
                    acc[vehicle._id] = Object.values(
                        (vehicle.route?.legs||[]).reduce((acc2,leg)=>{
                            acc2 = (leg.pickup.orders||[]).reduce((acc,order)=>{
                                acc[order._id!] = order;
                                return acc;
                            },acc2);
                            acc2 = (leg.dropoff.orders||[]).reduce((acc,order)=>{
                                acc[order._id!] = order;
                                return acc;
                            },acc2);
                            return acc2;
                        },{} as Record<number,OrderType.Hydrated<OrderType.Order<number>>>)
                    );
                    return acc;
                },{} as Record<number,OrderType.Hydrated<OrderType.Order<number>>[]>),
                fixedRoutes
            });
        });
    }
    render() {
        return MenuItem.withMenuItem("Vehicles",( alert:Alert ) => {
            this.alert = alert;
            const allVehicles = Object.values(this.state.wsPayload.vehiclesById);
            const vehicles    = allVehicles.filter(v => !v.archived_at).sort((v1,v2)=>(v1._id-v2._id));
            return (
                <div className="content wrapper section-card top-1">
                    <div className="vehicles-innersection-content">
                        {vehicles.map( (v,ndx) => {
                            const vehicle = <Vehicle
                                key             = {ndx}
                                fixedRoutes     = {this.state.fixedRoutes}
                                drivers         = {this.state.drivers}
                                vehicle         = {v}
                                orders          = {this.state.ordersByVid[v._id]||[]}
                                onDeleteDriver  = {(driver)=>this.onDeleteVehicleDriver(v,driver)}
                                onAddDriver     = {(driver)=>this.onAddVehicleDriver(v,driver)}
                                onUpdate        = {(delta)=>this.onUpdateVehicle(v,delta)}
                                onArchive       = {()=>this.onUpdateVehicle(v,{archived_at:Date.now()})}
                            />;
                            if( this.state.reassignedVid!==v._id )
                                return vehicle;
                            return [
                                <React.Fragment key={'reassignedVidModal'}>{this.getReassignRouteModal(v,this.state.reassignedDelta||{})}</React.Fragment>,
                                vehicle
                            ];
                        })}
                        <Vehicle
                            key             = {vehicles.length}
                            fixedRoutes     = {this.state.fixedRoutes}
                            drivers         = {this.state.drivers}
                            vehicle         = {this.newVehicle}
                            orders          = {[]}
                            onDeleteDriver  = {(driver)=>this.onDeleteVehicleDriver(this.newVehicle,driver)}
                            onAddDriver     = {(driver)=>this.onAddVehicleDriver(this.newVehicle,driver)}
                            onUpdate        = {(delta)=>Object.assign(this.newVehicle,delta)}
                            onCreate        = {()=>this.onCreateVehicle(this.newVehicle)}
                        />
                    </div>
                    <div>
                        Agency has
                        <strong>{vehicles.filter(v=>!v.unavailability_reason).length}</strong> available vehicles,
                        <strong>{vehicles.filter(v=>!!v.unavailability_reason).length}</strong> unavailable vehicles
                        (and <strong>{allVehicles.length-vehicles.length}</strong> archived ones)
                    </div>
                </div>
            );
        });
    }
}
