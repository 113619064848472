import {
    OpenWsResult
} from 'plugins/TecTransIT/types';
import * as TecTransITPlugin from 'plugins/TecTransIT';

export interface Packet {
    sn          : string;
    type        : string;
    time        : number;
}
export interface Data extends Packet {
    data        : {
        iCreatedTime    : number;
        id              : 1;
        iLongitude      : number;
        iLatitude       : number;
        count           : {
            name        : string;
            in          : number;
            out         : number;
            real?       : number;
            //allin       : number;
            //allout      : number;
            //ip          : number;
            //op          : number;
            //real        : number;
        }[]
    }[];
}

export interface Heartbeat extends Packet {
    data        : {
        iCreatedTime    : number;
    }[];
}

export interface UploadResponse {
    sn          : string;
    type        : string;
    time        : number;
}

export interface WSCommand {
    msgID       : number,
    protocolType: string
    errCode?    : number,
    response?   : WSCommand,
    [key:string]: any;
};

export const tcpPort       = 44544;

export const sendWsCommands = async ( 
    apcIPAddress    : (string|undefined),
    commands        : WSCommand[],
    timeout         : number = 10000
) : Promise<WSCommand[]> => {
    if( !apcIPAddress )
        throw Error(`Cannot find APC IP address`);
    commands.forEach(command => {
        delete command.response;
    });
    const wsAddress    = `ws://${apcIPAddress}/websocket`;
    let   openWsResult = undefined as (OpenWsResult|undefined);
    const countUnresolvedCommands = () => {
        return commands.reduce( (acc,command) => {
            return command.response ? acc : acc+1;
        },0);
    };
    const openSendAndWaitResponsePromise = new Promise<WSCommand[]>(async (resolve,reject) => {
        const timer = setTimeout( () => {
            reject(Error(`${countUnresolvedCommands()} unresolved commands after ${timeout}ms`));
        },timeout);
        const onEvent = (args:any) => {
            console.log("onEvent",args);
            if( typeof args !== 'object' ) {
                clearTimeout(timer);
                reject(Error(`Event args is not an object (${typeof args})`));
            }
            switch( args.event ) {
            case 'open':
                break;
            case 'exception':
                clearTimeout(timer);
                reject(Error(args.data));
                break;
            case 'text':
            case 'binary': {
                const data = JSON.parse(args.data);
                commands.forEach( command => {
                    if( command.msgID===data.msgID )
                        command.response = data as WSCommand;
                });
                if( countUnresolvedCommands()<=0 ) {
                    clearTimeout(timer);
                    resolve(commands);
                }
                break;
            }
            default:
                console.log(`WS event '${args.event}' from '${wsAddress}':`,args);
                break;
            }
        };
        try {
            openWsResult = await TecTransITPlugin.openWs({wsAddress,onEvent});
            try {
                await Promise.all(commands.map(command=>TecTransITPlugin.sendWs(openWsResult!,JSON.stringify(command))));
                console.log(`WS '${wsAddress}' is open and ${commands.length} commands are sent`);
            }
            catch( err ) {
                clearTimeout(timer);
                reject(Error(`Cannot send ${commands.length} commands to '${wsAddress}' (${(err as Error).message})`));
            }
        }
        catch( err ) {
            clearTimeout(timer);
            reject(Error(`Cannot open WS '${wsAddress} (${(err as Error).message})`));
        }
    });
    try {
        return await openSendAndWaitResponsePromise; 
    }
    catch( err ) {
        console.error(err);
        throw Error(`Cannot send commands to '${wsAddress}' (${(err as Error).message})`);
    }
    finally {
        if( openWsResult ) {
            TecTransITPlugin.closeWs(openWsResult).catch( err => {
                console.log(`Wasn't able to close '${wsAddress}' (${err.message})`);
            });
            openWsResult = undefined;
        }
    }
}
